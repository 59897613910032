// material ui
import Box from '@mui/material/Box';

// route
import { usePathname } from 'src/routes/hooks';

// project import
import Footer from 'src/layouts/main/ohmyappPlus/footer';
import Header from 'src/layouts/main/ohmyappPlus/header';

// constant
import { HEADER } from 'src/layouts/config-layout';

// ----------------------------------------------------------------------

const pathsOnDark = ['/career', '/career/', '/travel', '/travel/'];

const spacingLayout = [...pathsOnDark, '/', '/e-learning', '/e-learning/', '/marketing', '/marketing/'];

type Props = {
    children: React.ReactNode;
};

export default function MainLayout({ children }: Props) {
    const pathname = usePathname();

    const actionPage = (arr: string[]) => arr.some((path) => pathname === path);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
            <Header headerOnDark={actionPage(pathsOnDark)} />

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                }}
            >
                {!actionPage(spacingLayout) && <Spacing />}

                {children}
            </Box>

            <Footer />
        </Box>
    );
}

// ----------------------------------------------------------------------

function Spacing() {
    return (
        <Box
            sx={{
                height: { xs: HEADER.H_MOBILE, md: HEADER.H_DESKTOP },
            }}
        />
    );
}
