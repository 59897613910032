// third-party
import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { format } from 'date-fns';

// service
import { listDocument } from 'src/service/global-service';

// utils
import { dispatch } from 'src/store';

const initialState = {
    openCouponSelect: false,
    couponList: [] as any[],
};

const coupon = createSlice({
    name: 'coupon',
    initialState,
    reducers: {
        isOpenCouponSelect(state, action) {
            state.openCouponSelect = action.payload;
        },
        setCouponList(state, action) {
            state.couponList = action.payload;
        },
    },
});

// Reducer
export default coupon.reducer;

export const { isOpenCouponSelect, setCouponList } = coupon.actions;

export function getCouponList(userId: any) {
    return async () => {
        const document: any = {};

        document.metaCode = 'userCoupon';
        document.collectionName = 'userCoupon';

        const documentJson: any = {};
        documentJson.filters = [
            { method: 'eq', fieldName: '_createUser._id', value: userId },

            {
                method: 'eq',
                fieldName: 'status',
                value: 'done',
            },
        ];
        documentJson.skip = 0;
        documentJson.limit = 0;
        documentJson.sorts = [{ fieldName: '_createTime', method: 'descending' }];
        document.documentJson = documentJson;

        const response = await listDocument(document);
        const { data }: any = response;

        // 쿠폰 사용 기간을 계산하고, 쿠폰 리스트에 포맷팅된 날짜를 추가합니다.
        const formattedCouponList = data.map((row: any) => {
            if (!row?.expirationDate) {
                const createTime = new Date(row?._createTime?.$date);
                const monthsToAdd = row?.couponIssuanceJoin?.couponJoin?.couponDeadLineCount || 0;
                const newDate = new Date(
                    createTime.getFullYear(),
                    createTime.getMonth() + monthsToAdd,
                    createTime.getDate(),
                    createTime.getHours(),
                    createTime.getMinutes(),
                );
                const formattedDate = format(newDate, 'yyyy-MM-dd / HH:mm');

                return {
                    ...row,
                    limitDate: formattedDate,
                };
            } else {
                const expirationDate = new Date(row?.expirationDate);
                const isValidDate = !Number.isNaN(expirationDate.getTime());
                const formattedDate = isValidDate ? format(expirationDate, 'yyyy-MM-dd / HH:mm') : null;
                return {
                    ...row,
                    limitDate: formattedDate,
                };
            }
        });

        dispatch(setCouponList(formattedCouponList));
    };
}
