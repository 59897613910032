// third-party
import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    // status
    isInitialized: false,

    timeLineRows: [] as any[],
    timeLineRowsOnTask: [] as any[],
    recordsFiltered: 0,
    recordsTotal: 0,
    taskRecordsFiltered: 0,
    taskRecordsTotal: 0,

    isMore: true,

    // filter
    skip: 0,
    limit: 15,
};

const doctimeline = createSlice({
    name: 'doctimeline',
    initialState,
    reducers: {
        setInitialState: () => initialState,
        setInitialStateForSearch: (state) => {
            state.isMore = initialState.isMore;
            state.isInitialized = initialState.isInitialized;

            state.timeLineRows = initialState.timeLineRows;
            state.timeLineRowsOnTask = initialState.timeLineRowsOnTask;
            state.recordsFiltered = initialState.recordsFiltered;
            state.recordsTotal = initialState.recordsTotal;
            state.taskRecordsFiltered = initialState.taskRecordsFiltered;
            state.taskRecordsTotal = initialState.taskRecordsTotal;

            // filter
            state.skip = initialState.skip;
            state.limit = initialState.limit;
        },
        setRequestAggregateDatas(state, action) {
            const { data } = action.payload;

            const { recordsFiltered, recordsTotal } = data;

            if (data.data.length > 0) {
                state.isMore = true;
                state.skip += state.limit;

                const map = new Map();
                state.timeLineRows.concat(data.data).forEach((item) => {
                    map.set(item?._id?.$oid, item);
                });

                state.timeLineRows = Array.from(map.values());
                state.recordsFiltered = recordsFiltered;
                state.recordsTotal = recordsTotal;
            } else {
                state.isMore = false;
            }
        },
        // setRowsOnTask(state, action) {
        //     const { _id, _docTimelineReply } = action.payload;

        //     state.timeLineRowsOnTask =
        // },
        callbackAddComment(state) {
            state.isMore = true;
            state.skip = state.timeLineRows.length;
        },
        deleteSuccess(state, action) {
            const _id = action.payload;

            const $rows = _.cloneDeep(state.timeLineRows);
            const $filteredTimeLineRows = $rows.filter((timeLineRows) => timeLineRows?._id?.$oid !== _id?.$oid);

            state.timeLineRows = $filteredTimeLineRows;
        },
        // deleteTaskSuccess(state, action) {
        //     // const { _id, parentId } = action.payload;

        //     const _id = action.payload;

        //     const $rows = _.cloneDeep(state.timeLineRowsOnTask);
        //     // const $filteredTimeLineRow = $rows[].filter((timeLineRows) => timeLineRows?._id?.$oid !== _id?.$oid);
        //     const $filteredTimeLineRow = $rows.filter((timeLineRows) => timeLineRows?._id?.$oid !== _id?.$oid);

        //     state.timeLineRowsOnTask = $filteredTimeLineRow;
        // },
    },
});

// Reducer
export default doctimeline.reducer;

export const {
    setInitialState,
    setInitialStateForSearch,
    setRequestAggregateDatas,
    // setRowsOnTask,
    callbackAddComment,
    deleteSuccess,
    // deleteTaskSuccess,
} = doctimeline.actions;
