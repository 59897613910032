// third party
import { format, addDays } from 'date-fns';
import { baseUrl } from 'src/config-global';

// utils
import { isObject, isUndefined, isOwnProperty, isArray, isNull, isEmptyObject } from 'src/utils/typeof';

export const makedPipeline = (document: any) => {
    const pipeline: any = {};

    // $skip
    if (document.skip >= 0) {
        pipeline.$skip = document.skip;
    }

    // $limit
    if (document.limit) {
        pipeline.$limit = document.limit;
    }

    // $sort
    if (document.sorts && isArray(document.sorts)) {
        const $sorts: any[] = [];
        document.sorts.forEach((sort: any) => {
            if (sort.id && sort.method) {
                $sorts.push({
                    [sort.id]: sort.method === 'asc' ? 1 : -1,
                });
            }
        });

        pipeline.$sort = $sorts;
    }

    // $match
    if (document.matchs && isArray(document.matchs)) {
        const $match: any = {};
        const $or: any[] = [];

        document.matchs.forEach((match: any, index: number) => {
            const fieldName = match?.fieldName;
            const method = match?.method;
            const value = match?.value;
            const integratedParams = match?.integratedParams;

            if (fieldName && method) {
                if (isObject(value)) {
                    // 날짜 matchs
                    if (fieldName === '_createTime' || fieldName === '_updateTime') {
                        const start = value?.startDate;
                        const end = value?.endDate;

                        $match[`${fieldName}_string_${index}`] = {
                            $gte: format(start, 'yyyy-MM-dd').toString(),
                            $lte: format(end, 'yyyy-MM-dd').toString(),
                        };

                        pipeline.$addFields = {
                            [`${fieldName}_string_${index}`]: {
                                $dateToString: {
                                    date: `$${fieldName}`,
                                    timezone: 'Asia/Seoul',
                                    format: '%Y-%m-%d',
                                },
                            },
                        };
                    } else {
                        if (value.startDate) {
                            const start = value?.startDate;
                            const addDayEnd = addDays(new Date(value?.endDate), 1);

                            $match[fieldName] = {
                                $gte: format(start, 'yyyy-MM-dd').toString(),
                                $lte: format(addDayEnd, 'yyyy-MM-dd').toString(),
                            };
                        } else {
                            if (method) {
                                $match[fieldName] = { [`$${method}`]: JSON.parse(JSON.stringify(value)) };
                            } else {
                                $match[fieldName] = JSON.parse(JSON.stringify(value));
                            }
                        }
                    }
                } else {
                    if (integratedParams) {
                        // 통합 검색
                        // 우선 text형만 고려, method는 regex 고정
                        Object.entries(integratedParams).forEach(([key, value], index) => {
                            if (key === '_id') {
                                pipeline.$addFields = {
                                    [`${key}_string_${index}`]: {
                                        $toString: '$_id',
                                    },
                                };

                                $or.push({
                                    [`${key}_string_${index}`]: {
                                        $eq: value,
                                    },
                                });
                            } else {
                                $or.push({
                                    [key]: {
                                        [`$${method}`]: `^.*${value}.*$`,
                                        $options: 'mi',
                                    },
                                });
                            }
                        });
                    } else if (method === 'regex') {
                        // 일반 matchs
                        $match[fieldName] = {
                            [`$${method}`]: `^.*${value}.*$`,
                            $options: 'mi',
                        };
                    } else {
                        $match[fieldName] = { [`$${method}`]: value };
                    }
                }
            }
        });

        pipeline.$match = $match;

        if ($or.length > 0) {
            pipeline.$match = { ...pipeline.$match, $or };
        }
    }

    // $match
    if (document.groups) {
        const $group: any = {};
        document.groups.forEach((group: any) => {
            const fieldName = group?.fieldName;
            const method = group?.method;
            const value = group?.value;

            if (fieldName) {
                if (method) {
                    $group[fieldName] = value;
                } else {
                    // 일반 matchs
                    if (method === 'regex') {
                        $group[fieldName] = {
                            [`$${method}`]: `^.*${value}.*$`,
                            $options: 'mi',
                        };
                    } else {
                        $group[fieldName] = { [`$${method}`]: value };
                    }
                }
            }
        });
        pipeline.$group = $group;
    }

    // $skip
    if (document.groupSkip >= 0) {
        pipeline.$groupSkip = document.groupSkip;
    }

    // $limit
    if (document.groupLimit) {
        pipeline.$groupLimit = document.groupLimit;
    }

    // $sort
    if (document.groupSorts) {
        const $groupSorts: any[] = [];
        document.groupSorts.forEach((sort: any) => {
            if (sort.id && sort.method) {
                $groupSorts.push({
                    [sort.id]: sort.method === 'asc' ? 1 : -1,
                });
            }
        });

        pipeline.$groupSort = $groupSorts;
    }

    return pipeline;
};

const slashCheker = (value: any) => {
    return value?.charAt(0) !== '/' ? '/' : '';
};

export const getAvatarSource = (value: any) => {
    try {
        if (isUndefined(value)) {
            return '';
        }

        if (isArray(value)) {
            return value[0]?.path?.indexOf('http') > -1
                ? value[0]?.path
                : `${slashCheker(value[0].path)}${value[0].path}`;
        } else if (isObject(value) && value?.path) {
            return value?.path?.indexOf('http') > -1 ? value?.path : `${slashCheker(value?.path)}${value?.path}`;
        } else if (isObject(value)) {
            if (isArray(value?.avatar)) {
                return value?.avatar[0]?.path?.indexOf('http') > -1
                    ? value?.avatar[0]?.path
                    : `${slashCheker(value?.avatar[0]?.path)}${value?.avatar[0]?.path}`;
            } else {
                return value?.avatar?.indexOf('http') > -1
                    ? value?.avatar
                    : `${slashCheker(value?.avatar)}${value?.avatar}`;
            }
        } else {
            return value?.indexOf('http') > -1 ? value : `${baseUrl}${slashCheker(value)}${value}`;
        }
    } catch (err) {
        console.log(`getAvatarSource : ${err}`);
    }
};

export const getImageSource = (value: any) => {
    try {
        if (isUndefined(value)) {
            return '';
        }

        if (isArray(value)) {
            return value[0]?.path?.indexOf('http') > -1
                ? value[0]?.path
                : `${slashCheker(value[0]?.path)}${value[0]?.path}`;
        } else if (isObject(value)) {
            return value?.path?.indexOf('http') > -1 ? value?.path : `${slashCheker(value?.path)}${value?.path}`;
        } else {
            return value?.indexOf('http') > -1 ? value : `${baseUrl}${slashCheker(value)}${value}`;
        }
    } catch (err) {
        console.log(`getImageSource : ${err}`);
    }
};

export const generateObjectId = () => {
    try {
        // eslint-disable-next-line no-bitwise
        const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);

        const objectId = { $oid: '' };
        objectId.$oid =
            timestamp +
            'xxxxxxxxxxxxxxxx'
                // eslint-disable-next-line no-bitwise
                .replace(/[x]/g, () => ((Math.random() * 16) | 0).toString(16))
                .toLowerCase();

        return objectId;
    } catch (err) {
        console.log(`generateObjectId : ${err}`);
    }
};

export const linkMaker = (value: string) => {
    const urlRegex = /((http|https)?:\/\/[^\s]+)/g;
    const link = value.replace(urlRegex, (url) => {
        return `<a href="${url}" target="_blank" style="color : dodgerblue; word-break: break-all;">${url}</a>`;
    });

    return link;
};
