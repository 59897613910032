import { lazy } from 'react';

import { Outlet } from 'react-router-dom';

// ----------------------------------------------------------------------

// page
const MagazinePage = lazy(() => import('src/pages/ohmyappPlus/magazine'));
const MagazineDetailPage = lazy(() => import('src/sections/_ohmyappPlus/magazine-view/magazine-detail'));

// ----------------------------------------------------------------------

export const magazineRoutes = [
    {
        path: 'magazine',
        element: <Outlet />,
        children: [
            { element: <MagazinePage />, index: true },
            { path: 'detail/:oid', element: <MagazineDetailPage /> },
        ],
    },
];
