import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';

import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';

import { bgBlur } from 'src/theme/css';

import OhmyappPlusLogo from 'src/components/logo/ohmyappPlusLogo';

import { HEADER } from 'src/layouts/config-layout';
import HeaderShadow from 'src/layouts/common/header-shadow';

import NavMobile from 'src/layouts/main/nav/mobile';
import NavDesktop from 'src/layouts/main/nav/desktop';
import { navPlusConfig, navPlusConfigEn } from 'src/layouts/main/config-navigation';

// third party
import { useTranslation } from 'react-i18next';

// utils
import axios from 'src/utils/axios';
import { Typography } from '@mui/material';

// ----------------------------------------------------------------------

type Props = {
    headerOnDark: boolean;
};

export default function Header({ headerOnDark }: Props) {
    const theme = useTheme();

    const offset = useOffSetTop();

    const mdUp = useResponsive('up', 'md');

    const { i18n } = useTranslation();

    const [selectedLanguage, setSelectedLanguage] = useState('');

    const languageChange = (language: string) => {
        switch (language) {
            case 'ko':
                i18n.changeLanguage('ko');
                axios.defaults.headers.common.language = 'ko';
                break;
            default:
                i18n.changeLanguage('en');
                axios.defaults.headers.common.language = 'en';
                break;
        }

        setSelectedLanguage(language);
    };

    useEffect(() => {
        if (i18n.language) {
            switch (i18n.language) {
                case 'ko-KR':
                case 'ko':
                    setSelectedLanguage('ko');
                    break;
                default:
                    setSelectedLanguage('en');
                    break;
            }
        }
    }, [i18n.language]);

    const renderContent = (
        <>
            <Box sx={{ lineHeight: 0, position: 'relative' }}>
                <OhmyappPlusLogo />

                {/* <Link href="https://zone-docs.vercel.app/changelog" target="_blank" rel="noopener">
                    <Label
                        color="info"
                        sx={{
                            ml: 0.5,
                            px: 0.5,
                            top: -14,
                            left: 60,
                            height: 20,
                            fontSize: 11,
                            cursor: 'pointer',
                            position: 'absolute',
                        }}
                    >
                        v2.4.0
                    </Label>
                </Link> */}
            </Box>

            <>
                <Stack
                    flexGrow={1}
                    alignItems="center"
                    sx={{
                        height: 1,
                        display: { xs: 'none', md: 'flex' },
                    }}
                />

                <Box sx={{ flexGrow: { xs: 1, md: 'unset' } }} />
            </>

            <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-end">
                <Stack spacing={1} direction="row" alignItems="center">
                    {/* <Searchbar /> */}

                    {/* <SettingsButton /> */}
                    {mdUp && (
                        <NavDesktop
                            data={i18n.language === 'ko' ? navPlusConfig : navPlusConfigEn}
                            sx={{ mr: '48px' }}
                        />
                    )}
                </Stack>

                <Stack spacing={1} direction="row" alignItems="center">
                    <Box
                        sx={{ cursor: 'pointer', color: selectedLanguage !== 'en' ? '#9a9fa5' : '#1d2124' }}
                        onClick={() => languageChange('en')}
                    >
                        <Typography variant="body2">ENG</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '1px',
                            height: '15px',
                            backgroundColor: '#ebebeb',
                        }}
                    />
                    <Box
                        sx={{ cursor: 'pointer', color: selectedLanguage !== 'ko' ? '#9a9fa5' : '#1d2124' }}
                        onClick={() => languageChange('ko')}
                    >
                        <Typography variant="body2">KOR</Typography>
                    </Box>
                </Stack>
            </Stack>

            {!mdUp && <NavMobile data={i18n.language === 'ko' ? navPlusConfig : navPlusConfigEn} />}
        </>
    );

    return (
        <AppBar>
            <Toolbar
                disableGutters
                sx={{
                    height: {
                        xs: HEADER.H_MOBILE,
                        md: HEADER.H_DESKTOP,
                    },
                    transition: theme.transitions.create(['height', 'background-color'], {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.shorter,
                    }),
                    ...(headerOnDark && {
                        color: 'common.white',
                    }),
                    ...(offset && {
                        ...bgBlur({ color: theme.palette.background.default }),
                        color: 'text.primary',
                        height: {
                            md: HEADER.H_DESKTOP - 16,
                        },
                    }),
                }}
            >
                <Container
                    sx={{
                        height: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {renderContent}
                </Container>
            </Toolbar>

            {offset && <HeaderShadow />}
        </AppBar>
    );
}
