import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persister } from 'src/store';

import { SnackbarProvider } from 'notistack';
import SnackbarAction from 'src/components/snackbar/snackbar-action';

import App from './App';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persister}> */}
        <HelmetProvider>
            <BrowserRouter>
                <Suspense>
                    <SnackbarProvider
                        autoHideDuration={2500}
                        maxSnack={3}
                        action={(snackbarId) => {
                            return <SnackbarAction snackbarId={snackbarId} />;
                        }}
                    >
                        <App />
                    </SnackbarProvider>
                </Suspense>
            </BrowserRouter>
        </HelmetProvider>
        {/* </PersistGate> */}
    </Provider>,
);
