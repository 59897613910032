import { memo } from 'react';

import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import { RouterLink } from 'src/routes/components';
import { Image } from 'src/components/image';

// ----------------------------------------------------------------------

function OhmyappPlusLogo({ sx }: any) {
    const theme = useTheme();

    const fullLogo = (
        <Image
            alt="/assets/images/ohmyappPlus/ohmyapp-plus@3x.png"
            src="/assets/images/ohmyappPlus/ohmyapp-plus@3x.png"
            disabledEffect
        />
    );

    return (
        <Link component={RouterLink} href="/" color="inherit" aria-label="go to homepage" sx={{ lineHeight: 0 }}>
            <Box
                sx={{
                    width: 140,
                    lineHeight: 0,
                    cursor: 'pointer',
                    display: 'inline-flex',
                    ...sx,
                }}
            >
                {fullLogo}
            </Box>
        </Link>
    );
}

export default memo(OhmyappPlusLogo);
