// material-ui
import { Dialog, DialogContent, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useEffect } from 'react';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

const ProgressDialog = ({ open, handleClose, loaded, total, percent }: any) => {
    return (
        <Dialog
            open
            onClose={handleClose}
            keepMounted
            maxWidth="sm"
            aria-labelledby="dialog-title"
            aria-describedby="dialog-content"
            sx={{ zIndex: 99999 }}
        >
            {open && (
                <DialogContent sx={{ py: '24px' }}>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Typography variant="h2">💾</Typography>
                    </Box>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Typography sx={{ my: 1 }} variant="subtitle1">
                            {loaded || 0} MB / {total || 0} MB
                        </Typography>
                    </Box>
                    <BorderLinearProgress sx={{ my: 1 }} variant="determinate" value={percent || 0} />
                    <Typography sx={{ my: 1 }} variant="subtitle2">
                        업로드가 완료 될 동안 창을 닫지 마시고 잠시만 기다려주세요.
                    </Typography>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default ProgressDialog;
