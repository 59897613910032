// third-party
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    open: false,
    title: 'Title',
    context: 'Description',
    agreeText: 'Agree',
    disagreeText: 'Disagree',
    onAgree: null,
    maxWidth: 'md',
};

const dialog = createSlice({
    name: 'dialog',
    initialState,
    reducers: {
        openDialog(state, action) {
            const { open, title, context, agreeText, onAgree, disagreeText, maxWidth } = action.payload;

            state.open = open ?? initialState.open;
            state.title = title ?? initialState.title;
            state.context = context ?? initialState.context;
            state.agreeText = agreeText ?? initialState.agreeText;
            state.disagreeText = disagreeText ?? initialState.disagreeText;
            state.onAgree = onAgree ?? initialState.onAgree;
            state.maxWidth = maxWidth ?? initialState.maxWidth;
        },
        closeDialog: () => initialState,
    },
});

// Reducer
export default dialog.reducer;

export const { openDialog, closeDialog } = dialog.actions;
