import Link from '@mui/material/Link';
import Masonry from '@mui/lab/Masonry';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { alpha, styled, useTheme } from '@mui/material/styles';
import Stack, { StackProps } from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import Button, { buttonClasses } from '@mui/material/Button';

import { usePathname } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { _socials } from 'src/_mock';

import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';

import { NavSubListProps } from 'src/layouts/main/nav/types';
import { pageLinks, navConfigOrigin } from 'src/layouts/main/config-navigation';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Image } from 'src/components/image';

// ----------------------------------------------------------------------

const StyledAppStoreButton = styled(Button)(({ theme }) => ({
    flexShrink: 0,
    padding: '5px 12px',
    color: theme.palette.common.white,
    border: `solid 1px ${alpha(theme.palette.common.black, 0.24)}`,
    background: `linear-gradient(180deg, ${theme.palette.grey[900]} 0%, ${theme.palette.common.black} 100%)`,
    [`& .${buttonClasses.startIcon}`]: {
        marginLeft: 0,
    },
}));

// ----------------------------------------------------------------------

export default function Footer() {
    const theme = useTheme();

    const mdUp = useResponsive('up', 'md');

    const pathname = usePathname();

    const mobileList = navConfigOrigin.find((i) => i.title === 'Pages')?.children || [];

    const desktopList = pageLinks.sort((listA, listB) => Number(listA.order) - Number(listB.order));

    const renderLists = mdUp ? desktopList : mobileList;

    const isHome = pathname === '/';

    const { t } = useTranslation();

    const ohmyappPlusFooter = (
        <Box>
            <Divider />
            <Container sx={{ py: 8, textAlign: 'center' }}>
                <Stack>
                    <Stack alignItems="flex-start" sx={{ mb: '20px' }}>
                        <Logo sx={{ width: '130px', height: '100%', objectFit: 'cover' }} />
                    </Stack>
                    <Stack alignItems="flex-start">
                        <Typography sx={{ color: '#222', mb: '8px', fontSize: '13px', fontWeight: 600 }}>
                            {t('footer.company', '(주)꾸러기수비대')}
                        </Typography>
                        <Typography
                            sx={{ color: '#7a7a7a', fontSize: '13px', textAlign: { xs: 'left', md: 'center' } }}
                        >
                            {t(
                                'footer.companyInfo',
                                '대표 김경하 ㅣ 서울시 마포구 백범로 31길 21 서울창업허브 별관 408호 ㅣ 사업자등록번호 819-88-01959 ㅣ 통신판매업 신고번호 2022-서울강남-05615',
                            )}
                        </Typography>
                    </Stack>
                    <Stack spacing={2} direction="row">
                        <Stack alignItems="flex-start" sx={{ py: '20px' }}>
                            <Link href="https://www.youtube.com/@ohmyapp" target="_blank" rel="noopener">
                                <IconButton color="primary" sx={{ width: '38px', height: '38px', p: 0 }}>
                                    <Image alt="youtube" src="/assets/images/ohmyappPlus/sns/link-youtube@3x.png" />
                                </IconButton>
                            </Link>
                        </Stack>
                        <Stack alignItems="flex-start" sx={{ py: '20px' }}>
                            <Link href="https://www.instagram.com/ohmyapp.plus" target="_blank" rel="noopener">
                                <IconButton color="primary" sx={{ width: '38px', height: '38px', p: 0 }}>
                                    <Image alt="youtube" src="/assets/images/ohmyappPlus/sns/link-instagram@3x.png" />
                                </IconButton>
                            </Link>
                        </Stack>
                    </Stack>
                    <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        justifyContent={{ xs: 'center', md: 'space-between' }}
                        spacing={{ xs: 2, md: 1.5 }}
                    >
                        <Typography sx={{ color: '#7a7a7a', fontSize: '12px' }}>
                            {t('footer.copyright', 'Copyright 2023 K-Rangers, Inc. All rights reserved.')}
                        </Typography>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );

    const simpleFooter = (
        <Container sx={{ py: 8, textAlign: 'center' }}>
            <Logo single />

            <Typography variant="caption" component="div" sx={{ color: 'text.secondary' }}>
                © 2023. All rights reserved
            </Typography>
        </Container>
    );

    const mainFooter = (
        <>
            <Divider />

            <Container
                sx={{
                    overflow: 'hidden',
                    mt: '40px',
                    mb: '40px',
                }}
            >
                <Grid container spacing={3} justifyContent={{ md: 'space-between' }}>
                    <Grid xs={12} md={8} sx={{ mb: { xs: '10px', md: '0px' } }}>
                        <Stack spacing={{ xs: 3, md: 4 }}>
                            <Stack alignItems="flex-start" spacing={1}>
                                <Logo
                                    sx={{ width: '110px', height: '100%', objectFit: 'cover', ml: '2px', mt: '10px' }}
                                />

                                <Typography
                                    variant="body2"
                                    sx={{ color: '#222222', fontSize: '13px', fontWeight: 400 }}
                                >
                                    {t('ohmyappHomepage.main.section1.main_title1')}{' '}
                                    {t('ohmyappHomepage.main.section1.main_title2')}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>

            <Container>
                <Stack direction={{ xs: 'column', md: 'column' }} sx={{ mb: '40px', textAlign: 'left' }}>
                    <Typography
                        variant="caption"
                        sx={{ color: '#222222', fontSize: '12px', fontWeight: 700, mb: '8px' }}
                    >
                        {t('ohmyappHomepage.main.footer.inc')}
                    </Typography>
                    <Typography sx={{ color: '#7a7a7a', fontSize: '13px', mb: '40px' }}>
                        {t('ohmyappHomepage.main.footer.caption')}
                    </Typography>
                    <Stack
                        direction={{ xs: 'column', md: 'row' }}
                        justifyContent={{ xs: 'center', md: 'space-between' }}
                        spacing={{ xs: 2, md: 1.5 }}
                    >
                        <Typography sx={{ color: '#7a7a7a', fontSize: '13px' }}>
                            {t(
                                'ohmyappHomepage.main.footer.copyright',
                                'Copyright 2023 K-Rangers, Inc. All rights reserved.',
                            )}
                        </Typography>
                    </Stack>
                </Stack>
            </Container>
        </>
    );

    // return <footer>{isHome ? simpleFooter : mainFooter}</footer>;
    return <footer>{ohmyappPlusFooter}</footer>;
}

// ----------------------------------------------------------------------

export function ListDesktop({ list }: { list: NavSubListProps }) {
    const pathname = usePathname();

    return (
        <Stack spacing={1.5} alignItems="flex-start">
            <Typography variant="subtitle2">{list.subheader}</Typography>

            {list.items?.map((link) => {
                const active = pathname === link.path || pathname === `${link.path}/`;

                return (
                    <Link
                        component={RouterLink}
                        key={link.title}
                        href={link.path}
                        variant="caption"
                        sx={{
                            color: 'text.secondary',
                            '&:hover': {
                                color: 'text.primary',
                            },
                            ...(active && {
                                color: 'text.primary',
                                fontWeight: 'fontWeightSemiBold',
                            }),
                        }}
                    >
                        {link.title}
                    </Link>
                );
            })}
        </Stack>
    );
}

// ----------------------------------------------------------------------

export function ListMobile({ list }: { list: NavSubListProps }) {
    const pathname = usePathname();

    const listExpand = useBoolean();

    return (
        <Stack spacing={1.5} alignItems="flex-start">
            <Typography
                variant="subtitle2"
                onClick={listExpand.onToggle}
                sx={{
                    cursor: 'pointer',
                    display: 'inline-flex',
                    alignItems: 'center',
                }}
            >
                {list.subheader}
                <Iconify
                    width={16}
                    icon={listExpand.value ? 'carbon:chevron-down' : 'carbon:chevron-right'}
                    sx={{ ml: 0.5 }}
                />
            </Typography>

            <Collapse in={listExpand.value} unmountOnExit sx={{ width: 1 }}>
                <Stack spacing={1.5} alignItems="flex-start">
                    {list.items?.map((link) => (
                        <Link
                            component={RouterLink}
                            key={link.title}
                            href={link.path}
                            variant="caption"
                            sx={{
                                color: 'text.secondary',
                                '&:hover': {
                                    color: 'text.primary',
                                },
                                ...(pathname === `${link.path}/` && {
                                    color: 'text.primary',
                                    fontWeight: 'fontWeightSemiBold',
                                }),
                            }}
                        >
                            {link.title}
                        </Link>
                    ))}
                </Stack>
            </Collapse>
        </Stack>
    );
}

// ----------------------------------------------------------------------

function AppStoreButton({ ...other }: StackProps) {
    return (
        <Stack direction="row" flexWrap="wrap" spacing={2} {...other}>
            <StyledAppStoreButton startIcon={<Iconify icon="ri:apple-fill" width={28} />}>
                <Stack alignItems="flex-start">
                    <Typography variant="caption" sx={{ opacity: 0.72 }}>
                        Download on the
                    </Typography>

                    <Typography variant="h6" sx={{ mt: -0.5 }}>
                        Apple Store
                    </Typography>
                </Stack>
            </StyledAppStoreButton>

            <StyledAppStoreButton startIcon={<Iconify icon="logos:google-play-icon" width={28} />}>
                <Stack alignItems="flex-start">
                    <Typography variant="caption" sx={{ opacity: 0.72 }}>
                        Download from
                    </Typography>

                    <Typography variant="h6" sx={{ mt: -0.5 }}>
                        Google Play
                    </Typography>
                </Stack>
            </StyledAppStoreButton>
        </Stack>
    );
}
