// third-party
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoggedIn: false,
    userInfo: null,
    selectedTab: '',
};

const account = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setInitialState: () => initialState,
        setAuthenticated(state, action) {
            const { isLoggedIn, userInfo } = action.payload;

            state.isLoggedIn = isLoggedIn;
            state.userInfo = userInfo;
        },
        setSelectedTab(state, action) {
            state.selectedTab = action.payload;
        },
    },
});

// Reducer
export default account.reducer;

export const { setInitialState, setAuthenticated, setSelectedTab } = account.actions;
