// project import
import { baseUrl } from 'src/config-global';

// utils
import axios from 'src/utils/axios';

export const loginByEmail = async (document: any) => {
    const documentJson: any = {};

    documentJson.loginId = document.loginId;
    documentJson.loginPw = document.loginPw;
    documentJson.isShortToken = true;

    const parameters = new URLSearchParams();
    parameters.append('documentJson', JSON.stringify(documentJson));

    try {
        // 호출단에서 code 값 분기 처리
        const response = await axios.post(`${baseUrl}/bnb/user/token/loginByEmail`, parameters);
        return response.data;
    } catch (error) {
        console.log(`auth-service loginByEmail catch : ${error}`);
    }
};

export const socialLogin = async (document: any, isNickName: boolean) => {
    const documentJson: any = {};

    documentJson.userId = document.userId;
    documentJson.email = document.email;
    documentJson.avatar = document.profile;

    if (isNickName) documentJson.nickname = document.name;
    else documentJson.name = document.name;

    documentJson.socialType = document.socialType;
    documentJson.registerType = document.registerType;
    documentJson.isShortToken = true;

    const parameters = new URLSearchParams();
    parameters.append('documentJson', JSON.stringify(documentJson));

    try {
        // 호출단에서 code 값 분기 처리
        const response = await axios.post(`${baseUrl}/bnb/user/token/snsLogin`, parameters);
        return response.data;
    } catch (error) {
        console.log(`auth-service socialLogin catch : ${error}`);
    }
};

/** 로그인 정보 업데이트를 위한 getUserInfo
 *  token 필요 없음
 */
export const getUserInfo = async (document: any) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const parameters = new URLSearchParams();
    parameters.append('documentJson', JSON.stringify(document));

    try {
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

        // 호출단에서 code 값 분기 처리
        const response = await axios.post(`${baseUrl}/bnb/user/token/getUserInfo`, parameters);
        return response.data;
    } catch (error) {
        console.log(`auth-service getUserInfo catch : ${error}`);
    }
};

/** 로그인 정보 유지를 위한 getUserInfo
 *  token 필요
 */
export const getUserInfoInToken = async (document: any) => {
    const parameters = new URLSearchParams();
    parameters.append('documentJson', JSON.stringify(document));

    try {
        // 호출단에서 code 값 분기 처리
        const response = await axios.post(`${baseUrl}/bnb/user/token/getUserInfo`, parameters);
        return response.data;
    } catch (error) {
        console.log(`auth-service getUserInfo catch : ${error}`);
    }
};

export const setPasswordChange = async (document: any) => {
    const accessToken = window.localStorage.getItem('accessToken');

    const documentJson: any = {};
    documentJson.prePassword = document.prePassword;
    documentJson.newPassword = document.newPassword;

    const parameters = new URLSearchParams();
    parameters.append('documentJson', JSON.stringify(documentJson));

    try {
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

        const response = await axios.post(`${baseUrl}/bnb/user/passwordChange`, parameters);
        if (response.status === 200) {
            return response;
        }
    } catch (error) {
        console.log(`auth-service setPasswordChange catch : ${error}`);
    }
};

/** 인증코드 보내기 */
export const sendAuthcode = async (document: any) => {
    const documentJson: any = {};
    documentJson.email = document.email;
    documentJson.registerType = document.registerType;

    const parameters = new URLSearchParams();
    parameters.append('documentJson', JSON.stringify(documentJson));

    try {
        // 호출단에서 code 값 분기 처리
        const response = await axios.post(`${baseUrl}/bnb/user/sendAuthcode`, parameters);

        return response.data;
    } catch (error) {
        console.log(`auth-service sendAuthcode catch : ${error}`);
    }
};

/** 받은 인증코드로 검증 */
export const validAuthcode = async (document: any) => {
    const documentJson: any = {};
    documentJson.email = document.email;
    documentJson.authCode = document.authCode;
    documentJson.registerType = '';

    const parameters = new URLSearchParams();
    parameters.append('documentJson', JSON.stringify(documentJson));

    try {
        // 호출단에서 code 값 분기 처리
        const response = await axios.post(`${baseUrl}/bnb/user/validAuthcode`, parameters);

        return response.data;
    } catch (error) {
        console.log(`auth-service validAuthcode catch : ${error}`);
    }
};

/** 받은 인증코드로 회원가입 */
export const registerByEmailWithoutAuthCode = async (document: any) => {
    const documentJson: any = {};

    documentJson.userEmail = document.email;
    documentJson.loginPw = document.loginPw;

    documentJson.registerType = ''; // ohmyapp.ohmyapp.io 한정 공백
    documentJson.status = 'user';
    documentJson.team = ''; // ohmyapp.ohmyapp.io 한정 공백

    // 사용 목적, ohmyapp.ohmyapp.io, _code, purposeCode
    // documentJson.purpose = document.purpose;

    documentJson.userName = document.nickname;
    documentJson.nickname = document.nickname;
    documentJson.name = document.nickname;

    const defaultUserImage =
        'https://ohmyapp.ohmyapp.io/assets/users/ooIcon/2022/12/20/95aaa91c-543b-44e2-9c4d-5cc82a5cd53f_emptyuserblue.png';
    documentJson.avatar = document?.avatar ?? defaultUserImage;

    documentJson.isMarketing = document.isMarketing;
    documentJson.marketingAgreeDate = document.marketingAgreeDate;

    documentJson.origin = document.origin;
    documentJson.isShortToken = true;

    const parameters = new URLSearchParams();
    parameters.append('documentJson', JSON.stringify(documentJson));

    try {
        // 호출단에서 code 값 분기 처리
        const response = await axios.post(`${baseUrl}/bnb/user/token/registerByEmailWithoutAuthCode`, parameters);

        return response.data;
    } catch (error) {
        console.log(`auth-service registerByEmailWithoutAuthCode catch : ${error}`);
    }
};

/** 비밀번호 초기화 인증코드 발송 */
export const sendResetPasswordMail = async (document: any) => {
    const parameters = new URLSearchParams();
    parameters.append('documentJson', JSON.stringify(document));

    try {
        const response = await axios.post(`${baseUrl}/bnb/user/passwordForget`, parameters);

        return response;
    } catch (error) {
        console.log(`auth-service passwordForget catch : ${error}`);
    }
};

/** 받은 인증코드 검증 */
export const verifyCodeConfirm = async (document: any) => {
    const parameters = new URLSearchParams();
    parameters.append('documentJson', JSON.stringify(document));

    try {
        const response = await axios.post(`${baseUrl}/bnb/user/authConfirm`, parameters);
        return response;
    } catch (error) {
        console.log(`auth-service verifyCodeConfirm catch : ${error}`);
    }
};

/** 비밀번호 초기화 */
export const passwordChange = async (document: any) => {
    const parameters = new URLSearchParams();
    parameters.append('documentJson', JSON.stringify(document));

    try {
        const response = await axios.post(`${baseUrl}/bnb/user/passwordChange`, parameters);
        return response;
    } catch (error) {
        console.log(`auth-service passwordChange catch : ${error}`);
    }
};
