// third-party
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    step: 0,

    appName: '',
    appId: '',

    selectedColor: '#3182f6',

    isComplete: false,
};

const appmaker = createSlice({
    name: 'appmaker',
    initialState,
    reducers: {
        setInitialState: () => initialState,
        setStep(state, action) {
            state.step = action.payload;
        },
        setAppName(state, action) {
            state.appName = action.payload;
        },
        setAppId(state, action) {
            state.appId = action.payload;
        },
        setSelectedColor(state, action) {
            state.selectedColor = action.payload;
        },
        setIsComplete(state, action) {
            state.isComplete = action.payload;
        },
    },
});

// Reducer
export default appmaker.reducer;

export const { setInitialState, setStep, setSelectedColor, setAppName, setAppId } = appmaker.actions;
