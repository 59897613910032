import React, { useEffect, useState } from 'react';
import { initializeApp, getApps, getApp, FirebaseApp } from 'firebase/app';
import { getAnalytics, Analytics, logEvent } from 'firebase/analytics';
import { useLocation } from 'react-router-dom';

// Firebase 설정
const firebaseConfig = {
    apiKey: 'AIzaSyCwm7H6PnqRuCyP2eIohSoNDlXtsYBPXek',
    authDomain: 'ohmyapp-plus.firebaseapp.com',
    projectId: 'ohmyapp-plus',
    storageBucket: 'ohmyapp-plus.appspot.com',
    messagingSenderId: '562381955148',
    appId: '1:562381955148:web:808c50c8465abe9a96d4e9',
    measurementId: 'G-E5ZD6407TR',
};

interface FirebaseAnalyticsProviderProps {
    children: React.ReactNode;
}

export function FirebaseAnalyticsProvider({ children }: FirebaseAnalyticsProviderProps) {
    const location = useLocation();
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const [analytics, setAnalytics] = useState<Analytics | null>(null);

    useEffect(() => {
        // Firebase 앱이 이미 초기화되었는지 확인
        if (!getApps().length) {
            const app: FirebaseApp = initializeApp(firebaseConfig);
            const analyticsInstance = getAnalytics(app);
            setAnalytics(analyticsInstance);
        } else {
            const app: FirebaseApp = getApp();
            const analyticsInstance = getAnalytics(app);
            setAnalytics(analyticsInstance);
        }
        setIsInitialized(true);
    }, []);

    useEffect(() => {
        if (isInitialized && analytics) {
            logEvent(analytics, 'ohmyappPlusPageView', {
                page_path: location.pathname,
            });
        }
    }, [location, isInitialized, analytics]);

    return <>{children}</>;
}

export default FirebaseAnalyticsProvider;
