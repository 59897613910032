import { memo } from 'react';

import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

import { RouterLink } from 'src/routes/components';

// project import
import { Image } from 'src/components/image';

// ----------------------------------------------------------------------

interface LogoProps extends BoxProps {
    single?: boolean;
}

function Logo({ single = false, sx }: LogoProps) {
    const theme = useTheme();

    const PRIMARY_MAIN = theme.palette.primary.main;

    const singleLogo = <Image disabledEffect src="/assets/images/ohmyappPlus/ohmyapp-plus@3x.png" />;

    const fullLogo = <Image disabledEffect src="/assets/images/ohmyappPlus/ohmyapp-plus@3x.png" />;

    return (
        <Link component={RouterLink} href="/" color="inherit" aria-label="go to homepage" sx={{ lineHeight: 0 }}>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    lineHeight: 0,
                    cursor: 'pointer',
                    display: 'inline-flex',
                    ...sx,
                }}
            >
                {single ? singleLogo : fullLogo}
            </Box>
        </Link>
    );
}

export default memo(Logo);
