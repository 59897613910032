import { useEffect, useState } from 'react';

// third party
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguaeDetector from 'i18next-browser-languagedetector';

// project import
import { SplashScreen } from 'src/components/loading-screen';

// serivce
import { listDocument } from 'src/service/global-service';

// utils
import axios from 'src/utils/axios';

type Props = {
    children: React.ReactNode;
};

type LanguageDataType = {
    languageCode: string;
    stringSet: object;
};

export default function LanguageProvider({ children }: Props) {
    const [isInitialized, setIsInitialized] = useState(false);
    useEffect(() => {
        const init = async () => {
            try {
                const document: any = {};
                document.metaCode = 'ohmyappPlusManage';
                document.collectionName = 'ohmyappPlusManage';
                document.documentJson = {};

                const response = await listDocument(document);
                const { data }: any = response;

                const i18nInit: any = {
                    // for all options read: https://www.i18next.com/overview/configuration-options
                    // 배포시 debug false로 업데이트
                    debug: false,
                    fallbackLng: 'ko',
                    interpolation: {
                        escapeValue: false,
                    },
                    resources: {},
                };

                if (data && data?.length > 0) {
                    data.forEach((d: LanguageDataType) => {
                        if (d.languageCode && d.stringSet) {
                            i18nInit.resources[d.languageCode] = {
                                translation: {
                                    ...d.stringSet,
                                },
                            };
                        }
                    });
                }

                i18n.use(LanguaeDetector) // 사용자 언어 탐지
                    .use(initReactI18next) // i18n 객체를 react-18next에 전달
                    .init({
                        ...i18nInit,
                    });

                switch (i18n.language) {
                    case 'ko-KR':
                    case 'ko':
                        i18n.changeLanguage('ko');
                        axios.defaults.headers.common.language = 'ko';
                        break;
                    default:
                        i18n.changeLanguage('en');
                        axios.defaults.headers.common.language = 'en';
                        break;
                }

                setIsInitialized(true);
            } catch (err) {
                console.error(`Language provider Exception :${err}`);
            }
        };

        init();
    }, []);

    if (isInitialized !== undefined && !isInitialized) {
        return <SplashScreen />;
    }

    return <>{children}</>;
}
