// react
import { forwardRef } from 'react';

// material ui
import Link from '@mui/material/Link';

// project import
import Iconify from 'src/components/iconify';
import { RouterLink } from 'src/routes/components';
import SvgColor from 'src/components/svg-color';
import { StyledNavItem } from 'src/layouts/main/nav/desktop/styles';

// types
import { NavItemProps } from 'src/layouts/main/nav/types';

// third party
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export const NavItem = forwardRef<HTMLDivElement, NavItemProps>(
    ({ item, open, active, subItem, externalLink, ...other }, ref) => {
        const { t } = useTranslation();
        const renderContent = (
            <StyledNavItem ref={ref} disableRipple subItem={subItem} active={active} open={open} {...other}>
                {/* 헤더 메뉴 다국어 */}
                {t(item.title)}
                {externalLink && (
                    <SvgColor
                        src="/assets/icons/ic_external.svg"
                        sx={{ width: 16, height: 16, color: '#9a9fa5', ml: '8px' }}
                    />
                )}
            </StyledNavItem>
        );

        // ExternalLink
        if (externalLink) {
            return (
                <Link
                    href={item.path}
                    target="_blank"
                    rel="noopener"
                    color="inherit"
                    underline="none"
                    sx={{ display: 'flex', alignItems: 'center' }}
                >
                    {renderContent}
                </Link>
            );
        }

        // Has child
        if (item.children) {
            return renderContent;
        }

        // Default
        return (
            <Link component={RouterLink} href={item.path} color="inherit" underline="none">
                {renderContent}
            </Link>
        );
    },
);
