// project import
import { baseUrl } from 'src/config-global';

// utils
import axios from 'src/utils/axios';
import { makedPipeline } from 'src/utils/functions';

/** 데이터 리스트 호출 */
export const listDocument = async (document: any) => {
    const parameters = new URLSearchParams();

    parameters.append('metaCode', document.metaCode);
    parameters.append('collectionName', document.collectionName);
    parameters.append('documentJson', JSON.stringify(document.documentJson));

    try {
        const response = await axios.post(`${baseUrl}/bnb/list`, parameters);
        if (response.status === 200) {
            return response;
        }
    } catch (error) {
        console.error(`global-service listDocument : ${error}`);
    }
};

/** 데이터 생성 */
export const createDocument = async (document: any) => {
    const parameters = new URLSearchParams();

    parameters.append('metaCode', document.metaCode);
    parameters.append('collectionName', document.collectionName);
    parameters.append('documentJson', JSON.stringify(document.documentJson));

    try {
        const response = await axios.post(`${baseUrl}/bnb/create`, parameters);
        const { data }: any = response;
        const { code }: any = data;

        if (code === '1' || code === 1) {
            return data;
        }
    } catch (error) {
        console.error(`global-service createDocument : ${error}`);
    }
};

/** 데이터 생성/수정 */
export const upsertDocument = async (document: any) => {
    const parameters = new URLSearchParams();

    parameters.append('metaCode', document.metaCode);
    parameters.append('collectionName', document.collectionName);
    parameters.append('documentJson', JSON.stringify(document.documentJson));

    try {
        const response = await axios.post(`${baseUrl}/bnb/upsert`, parameters);
        const { data }: any = response;
        const { code }: any = data;

        if (code === '1' || code === 1) {
            return data;
        }
    } catch (error) {
        console.error(`global-service upsertDocument : ${error}`);
    }
};

/** 데이터 수정 */
export const updateDocument = async (document: any) => {
    const parameters = new URLSearchParams();

    parameters.append('metaCode', document.metaCode);
    parameters.append('collectionName', document.collectionName);
    parameters.append('documentJson', JSON.stringify(document.documentJson));

    try {
        const response = await axios.post(`${baseUrl}/bnb/update`, parameters);
        const { data }: any = response;
        const { code }: any = data;

        if (code === '1' || code === 1) {
            return data;
        }
    } catch (error) {
        console.error(`global-service updateDocument : ${error}`);
    }
};

/** 데이터 삭제 */
export const deleteDocument = async (document: any) => {
    const parameters = new URLSearchParams();

    parameters.append('metaCode', document.metaCode);
    parameters.append('collectionName', document.collectionName);
    parameters.append('documentJson', JSON.stringify(document.documentJson));

    try {
        const response = await axios.post(`${baseUrl}/bnb/delete`, parameters);
        const { data }: any = response;
        const { code }: any = data;

        if (code === '1' || code === 1) {
            return data;
        }
    } catch (error) {
        console.error(`global-service deleteDocument : ${error}`);
    }
};

/** 데이터 삭제 */
export const deleteAllDocument = async (document: any) => {
    const parameters = new URLSearchParams();

    parameters.append('metaCode', document.metaCode);
    parameters.append('collectionName', document.collectionName);
    parameters.append('documentJson', JSON.stringify(document.documentJson));

    try {
        const response = await axios.post(`${baseUrl}/bnb/deleteAll`, parameters);
        const { data }: any = response;
        const { code }: any = data;

        if (code === '1' || code === 1) {
            return data;
        }
    } catch (error) {
        console.error(`global-service deleteDocument : ${error}`);
    }
};

/** 데이터 리스트 호출 for aggreate */
export const aggregateForTable = async (document: any) => {
    const documentJson: any = {};
    documentJson.pipeline = makedPipeline(document);

    const parameters = new URLSearchParams();
    parameters.append('metaCode', document.metaCode);
    parameters.append('collectionName', document.collectionName);
    parameters.append('documentJson', JSON.stringify(documentJson));

    try {
        const response = await axios.post(`${baseUrl}/bnb/aggregateForTable`, parameters);
        return response;
    } catch (error) {
        console.log(`global-service aggregateForTable : ${error}`);
    }
};

/** 데이터 리스트 호출 시 설정된 docTimeLine을 붙혀서 줌 */
export const aggregateForTableWithDocTimeline = async (document: any) => {
    const documentJson: any = {};
    documentJson.pipeline = makedPipeline(document);

    const parameters = new URLSearchParams();
    parameters.append('metaCode', document.metaCode);
    parameters.append('collectionName', document.collectionName);
    parameters.append('documentJson', JSON.stringify(documentJson));

    try {
        const response = await axios.post(`${baseUrl}/bnb/aggregateForTableWithDocTimeline`, parameters);
        return response;
    } catch (error) {
        console.log(`global-service aggregateForTableWithDocTimeline : ${error}`);
    }
};

/** 몽고DB 쿼리를 있는 그래돌 작성해서 호출 */
export const aggregateAtDocTimeline = async (document: any, component: string) => {
    const documentJson: any = {};
    documentJson.pipeline = makedPipeline(document);
    documentJson.component = component;

    const parameters = new URLSearchParams();
    parameters.append('metaCode', document.metaCode);
    parameters.append('collectionName', document.collectionName);
    parameters.append('documentJson', JSON.stringify(documentJson));

    try {
        const response = await axios.post(`${baseUrl}/bnb/aggregateAtDocTimeline`, parameters);
        return response;
    } catch (error) {
        console.log(`global-service aggregateAtDocTimeline : ${error}`);
    }
};

// 한글을 제거하고 랜덤 코드를 추가하는 함수
function removeKoreanAndAddRandomCode(filename: string) {
    // 한글(자모 및 완성형), 공백 제거
    const noKoreanNoSpace = filename.replace(/[\u3131-\u318E\uAC00-\uD7A3\s]/g, '');
    // 랜덤 코드 생성 (예: 8자리 랜덤 문자열)
    const randomCode = Math.random().toString(36).substr(2, 8);
    // 확장자 추출
    const extension = noKoreanNoSpace.split('.').pop();
    // 확장자가 있을 경우, 파일명과 확장자 사이에 랜덤 코드 삽입
    // 확장자가 없을 경우, 그냥 랜덤 코드를 추가
    return `${noKoreanNoSpace.replace(new RegExp(`.${extension}$`), '')}_${randomCode}${
        extension ? `.${extension}` : ''
    }`;
}

/** 파일 업로드 함수 */
export const uploadFileService = async (document: any, files: any, progress?: any) => {
    const parameters = new FormData();

    files.forEach((file: any) => {
        // 파일명 수정
        const newFileName = removeKoreanAndAddRandomCode(file.name);
        const newFile = new File([file], newFileName, { type: file.type });

        parameters.append('file', newFile);
    });
    parameters.append('documentJson', JSON.stringify(document));

    try {
        const response = await axios.post(`${baseUrl}/bnb/upload/uploadFileWithMeta`, parameters, progress);

        const { data }: any = response;
        const { code } = data;

        if (code === '1' || code === 1) {
            return data;
        }
    } catch (error) {
        console.log(`global-service uploadFileWithMeta : ${error}`);
    }
};

/** 이벤트 플로우 호출 함수 */
export const processEventFlow = async (document: any) => {
    const parameters = new URLSearchParams();

    parameters.append('eventType', document.eventType);
    parameters.append('collectionName', document.collectionName);
    parameters.append('documentJson', JSON.stringify(document.documentJson));

    try {
        const response = await axios.post(`${baseUrl}/bnb/eventFlow`, parameters);

        return response;
    } catch (error) {
        console.log(`global-service callEventFlow catch : ${error}`);
    }
};

/** 오마이앱에서 관리자 웹 이동하였을때 오마이앱 계정 연동하여 관리자 웹에 로그인 없이 들어갈 수 있게 호출함 */
export const ohmyappLoginIntegration = async (document: any) => {
    const parameters = new URLSearchParams();

    parameters.append('serviceId', document.serviceId);
    parameters.append('isShortToken', document.isShortToken);

    try {
        const response = await axios.post(`https://${document.domain}/bnb/user/token/ohmyappLogin`, parameters);

        const { data }: any = response;
        const { code }: any = data;

        if (code === '1' || code === 1) {
            return data;
        }
    } catch (error) {
        console.error(`custom-service ohmyappLoginIntegration : ${error}`);
    }
};
