import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import CompactLayout from 'src/layouts/compact';
import AuthCoverLayout from 'src/layouts/auth/cover';
import { SplashScreen } from 'src/components/loading-screen';
import AuthBackgroundLayout from 'src/layouts/auth/background';
import AuthTermsBackgroundLayout from 'src/layouts/auth/terms-background';
import AuthIllustrationLayout from 'src/layouts/auth/illustration';
import GuestGuard from 'src/routes/routes-guard/GuestGuard';

// ----------------------------------------------------------------------

// ohmyapp
const LoginBackgroundPage = lazy(() => import('src/pages/auth/ohmyapp/login-background'));
const RegisterBackgroundPage = lazy(() => import('src/pages/auth/ohmyapp/register-background'));
const ForgotPasswordPage = lazy(() => import('src/pages/auth/ohmyapp/forgot-password'));
const VerifyPage = lazy(() => import('src/pages/auth/ohmyapp/verify'));
const ResetPasswordPage = lazy(() => import('src/pages/auth/ohmyapp/reset-password'));

// default
const LoginCoverPage = lazy(() => import('src/pages/auth/login-cover'));
const LoginIllustrationPage = lazy(() => import('src/pages/auth/login-illustration'));
const RegisterCoverPage = lazy(() => import('src/pages/auth/register-cover'));
const RegisterIllustrationPage = lazy(() => import('src/pages/auth/register-illustration'));

// ----------------------------------------------------------------------

export const authRoutes = [
    {
        path: 'auth',
        element: (
            <GuestGuard>
                <Suspense fallback={<SplashScreen />}>
                    <Outlet />
                </Suspense>
            </GuestGuard>
        ),
        children: [
            // ohmyapp
            {
                path: 'login-background',
                element: (
                    <AuthBackgroundLayout>
                        <LoginBackgroundPage />
                    </AuthBackgroundLayout>
                ),
            },
            {
                path: 'register-background',
                element: (
                    <AuthBackgroundLayout>
                        <RegisterBackgroundPage />
                    </AuthBackgroundLayout>
                ),
            },
            // default
            {
                path: 'login-cover',
                element: (
                    <AuthCoverLayout
                        title="Hi, Welcome Back"
                        images={[
                            '/assets/images/travel/travel_post_01.jpg',
                            '/assets/images/travel/travel_post_03.jpg',
                        ]}
                    >
                        <LoginCoverPage />
                    </AuthCoverLayout>
                ),
            },
            {
                path: 'register-cover',
                element: (
                    <AuthCoverLayout
                        title={`Manage The Job \n More Effectively`}
                        images={[
                            '/assets/images/travel/travel_post_01.jpg',
                            '/assets/images/travel/travel_post_03.jpg',
                        ]}
                    >
                        <RegisterCoverPage />
                    </AuthCoverLayout>
                ),
            },
            {
                path: 'login-illustration',
                element: (
                    <AuthIllustrationLayout>
                        <LoginIllustrationPage />
                    </AuthIllustrationLayout>
                ),
            },
            {
                path: 'register-illustration',
                element: (
                    <AuthIllustrationLayout>
                        <RegisterIllustrationPage />
                    </AuthIllustrationLayout>
                ),
            },
            {
                element: (
                    <CompactLayout>
                        <Outlet />
                    </CompactLayout>
                ),
                children: [
                    { path: 'forgot-password', element: <ForgotPasswordPage /> },
                    { path: 'verify', element: <VerifyPage /> },
                    { path: 'reset-password', element: <ResetPasswordPage /> },
                ],
            },
        ],
    },
];
